<template>



    <div class="card rounded-3 shadow-sm " style="margin: 20px;">
      <div class="card-header">
        <h4 class="my-0 fw-normal">{{ title }}</h4>
      </div>
      <div class="card-body" style="padding-bottom: 50px;">
        <!-- <h1 class="card-title pricing-card-title">{{ title }}</h1> -->
        <div class="ql-editor">
          <div v-html="maintext">
        </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="btn-group">
            <!-- <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
            <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button> -->
          </div>
          <small class="text-body-secondary">{{ erstellt }}</small>
 
          <!-- <button v-if="isAdmin" type="button" @click="delPit" class="btn btn-tool text-danger">
            <i class="fas fa-trash"></i>
          </button> -->
        </div>
      </div>
    </div>


  </template>
  
  <script>
  export default {
    props: ['id','Benutzer_id', 'title', 'maintext','category', 'erstellt'],
    emits: ['Item-Del'],
    data() {
      return {
      };
    },
    computed: {
        isAdmin() {
          return this.$store.getters.isAdmin;
        },
    },
    methods: {
      delPit() {
        console.log("emit Del"+this.title);
        this.$emit('Item-Del',this.id);
      },
    }
  }
  </script>
