<template>
    <main>
        <h2>Praktische IT Tipps</h2>
        <hr>
        <h3>ID: {{pitId}}</h3>
        <input type="text" name="title" v-model="pitsTitle">
        <select name="categories" v-model="pitCategory" id="categorielist">
            <option selected value="win">Windows</option>
            <option value="sp">Smartphones</option>
            <option value="in">Internet&Network</option>
            <option value="gen">Allgemein</option>
        </select>
        <vue-editor 
        id="editor"
        useCustomImageHandler
        @image-added="handleImageAdded"
        v-model="pitContent"
        ></vue-editor>
        
        <button class="btn btn-primary" type="button" @click="savePit">Speichern</button>
        <hr>
        <h4 class="subtitle is-2">Pitlist</h4>
        <div v-if="isLoading">
            <base-spinner></base-spinner>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" v-else-if="hasPits&&currentView=='shortitem'">
          <pits-short-item
            v-for="sPit in PitsList"
            :key="sPit.id"
            :id="sPit.id"
            :Benutzer_id="sPit.Benutzer_id"
            :title="sPit.title"
            :maintext="sPit.maintext"
            :category="sPit.category"
            :erstellt="sPit.erstellt"
          ></pits-short-item>
        </div>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" v-else-if="hasPits&&currentView=='item'">
          <pits-item 
            v-for="sPit in PitsList"
            :key="sPit.id"
            :id="sPit.id"
            :Benutzer_id="sPit.Benutzer_id"
            :title="sPit.title"
            :maintext="sPit.maintext"
            :category="sPit.category"
            :erstellt="sPit.erstellt"
          ></pits-item>
        </div>
        <div class="row " v-else-if="hasPits&&currentView=='list'">
          <pits-list-item 
            v-for="sPit in PitsList"
            :key="sPit.id"
            :id="sPit.id"
            :Benutzer_id="sPit.Benutzer_id"
            :title="sPit.title"
            :maintext="sPit.maintext"
            :category="sPit.category"
            :erstellt="sPit.erstellt"
            @Item-Edit="itemEdit"
            @Item-Del="itemDel"
          ></pits-list-item>
        </div>
        <h3 v-else>Keine Daten gefunden.</h3>
    </main>
</template>

<script>
import  axios   from 'axios';

import { VueEditor } from "vue3-editor";
import PitsShortItem from '../../components/pit/PitsShortItem.vue';
import PitsItem from '../../components/pit/PitsItem.vue';
import PitsListItem from '../../components/pit/PitsListItem.vue';
export default {
  computed: {
    hasPits() {
      // console.log(this.$store.getters['profile/HasProfiles']);
      // return !this.isLoading && this.$store.getters['profile/HasProfiles'];
      console.log(this.$store.getters.HasPitsData);
      return !this.isLoading && this.$store.getters.HasPitsData;
    },
    PitsList() {
      console.log("Pits: ");
      // console.log(this.$store.getters['profile/Profiles']);
      // return !this.isLoading && this.$store.getters['profile/Profiles'];
      console.log(this.$store.getters.PitsData);
      return !this.isLoading && this.$store.getters.PitsData;
    },
  },
  components: {
    PitsShortItem,
    PitsListItem,
    PitsItem,
    VueEditor
  },
  data() {
    return {
      pitId: null,
      pitsTitle: '',
      pitCategory: '',
      pitContent: '',
      isLoading: false,
      error: null,
      currentView: 'list',
    };
  },
  created() {
    this.loadPits();
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var togglerBody = document.getElementById('navbarCollapse');
      try {
        togglerBody.classList.remove("show");
      } catch (error) {
        togglerBody.classList.remove("hide");
      } 

    },
    async loadPits(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getPitsdata', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
          limit: 1000,
        });

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    async savePit() {
      //call method to insert News to DB
      this.isLoading = true;
      console.log("isloading true");
      try {
              await this.$store.dispatch('addPit',{
                  token: this.$store.state.auth.sessiontoken,
                  userid: this.$store.getters.userId,
                  id: this.pitId,
                  title: this.pitsTitle,
                  category: this.pitCategory,
                  maintext: this.pitContent,
              });

              this.loadPits();
              this.isLoading = false;
              this.pitsTitle = "";
              this.pitContent = "";
              this.pitCategory = "";
              this.pitId = null;
              console.log("isloading false");
              console.log(this.pitCategory);
              console.log(this.pitContent);

      } catch (error) {
          
          this.isLoading = false;
          console.log("isloading false");
          this.error = error.message;
      }
    },
    async delPit(id) {
      //call method to insert News to DB
      this.isLoading = true;
      console.log("isloading true");
      try {
              await this.$store.dispatch('delPit',{
                  token: this.$store.state.auth.sessiontoken,
                  userid: this.$store.getters.userId,
                  id: id
              });

              this.loadPits();
              this.isLoading = false;
              this.pitsTitle = "";
              this.pitContent = "";
              this.pitCategory = "";
              console.log("isloading false");
              console.log(this.pitCategory);
              console.log(this.pitContent);

      } catch (error) {
          
          this.isLoading = false;
          console.log("isloading false");
          this.error = error.message;
      }
    },
    itemEdit(id,Benutzer_id,title,maintext,category) {
      console.log('test message nach emit im parent mit dem titel: '+ title);
      // console.log(title);
      // console.log(maintext);
      this.pitId = id;
      this.pitsTitle = title;
      this.pitCategory = category;
      this.pitContent = maintext;

    },
    itemDel(id) {
      console.log('test message nach emit im parent mit der id: '+ id);
      this.delPit(id);

    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("image", file);

      axios({
        url: "https://kundenportal.sidanet.at/vueapi/customImageUploader.php",
        method: "POST",
        data: formData
      })
        .then(result => {
          console.log(result);
          const url = result.data.url; // Get url from response
          console.log(url);
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
};
</script>