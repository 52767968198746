import {createRouter, createWebHistory} from 'vue-router';

import Login from './Pages/Login.vue';
import Register from './Pages/Register.vue';
import NotFound from './Pages/NotFound.vue';
import Dashboard from './Pages/admin/Dashboard.vue';
import Customers from './Pages/admin/Customers.vue';
import PhoneHistory from './Pages/admin/PhoneHistory.vue';
import NewsFeed from './Pages/admin/NewsFeed.vue';
import PitOrganizer from './Pages/admin/PitOrganizer.vue';
import Pit from './Pages/Pit.vue';
import Profile from './Pages/profile/Profile.vue';
import Abo from './Pages/abo/Abo.vue';
import AdminAbo from './Pages/admin/AdminAbo.vue';
import ProductInfoCaller from './Pages/shop/ProductInfoCaller.vue';
import Home from './Pages/Home.vue';
import FAQ from './Pages/FAQ.vue';
import Checkout from './Pages/shop/Checkout.vue';
import SelectAbo from './Pages/shop/SelectAbo.vue';
import DataProtection from './Pages/DataProtection.vue';
import DokuCaller from './Pages/doku/DokuCaller.vue';
import ProductInfoBasar from './Pages/shop/ProductInfoBasar.vue';
import ProductInfoWaitery from './Pages/shop/ProductInfoWaitery.vue';
import FreeTools from './Pages/FreeTools.vue';
import Impressum from './Pages/Impressum.vue';
import TestBuy from './Pages/shop/TestBuy.vue';
import PaypalSuccess from './Pages/PaypalSuccess.vue';
import PaypalCancelled from './Pages/PaypalCancelled.vue';
import ProductPriceCaller from './Pages/shop/ProductPriceCaller.vue';
import store from './store/index.js';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/home' },
        { path: '/login', component:Login, meta: { requiresUnauth: true}  },
        { path: '/register', component:Register, meta: { requiresUnauth: true} },
        { path: '/profile', component:Profile, meta: { requiresAuth: true}  },
        { path: '/abos', component:Abo, meta: { requiresAuth: true}  },
        { path: '/adminabos', component:AdminAbo, meta: { requiresAuth: true,requiresAdmin : true}  },
        { path: '/admindashboard', component:Dashboard , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/customers', component:Customers , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/phonehistory', component:PhoneHistory , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/pitOrganizer', component:PitOrganizer , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/home', component:Home },
        { path: '/faq', component:FAQ },
        { path: '/pit', component:Pit },
        { path: '/shop/productcaller', component:ProductInfoCaller },
        { path: '/shop/productbasar', component:ProductInfoBasar },
        { path: '/shop/productwaitery', component:ProductInfoWaitery },
        { path: '/shop/productpricecaller', component:ProductPriceCaller },
        { path: '/shop/checkout', component:Checkout , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/shop/selectabo', component:SelectAbo , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/paypalsuccess', component:PaypalSuccess, meta: { requiresAuth: true}  },
        { path: '/paypalcancelled', component:PaypalCancelled, meta: { requiresAuth: true}  },
        { path: '/dataprotection', component:DataProtection },
        { path: '/doku/dokucaller', component:DokuCaller },
        { path: '/freetools', component:FreeTools },
        { path: '/newsfeed', component:NewsFeed , meta: { requiresAuth: true,requiresAdmin : true} },
        { path: '/impressum', component:Impressum },
        { path: '/testbuy', component:TestBuy },
        { path: '/:notFound(.*)', component:NotFound }
    ]
});




router.beforeEach(function(to,_,next) {
    console.log('isAuth: ' + store.getters.isAuthenticated);
    console.log('isAdmin: ' + store.getters.isAdmin);

    if(to.meta.requiresAuth && !store.getters.isAuthenticated) {
        if(to.meta.requiresAdmin && !store.getters.isAdmin) {
            next('/dataprotection'); 
        } else {
            next('/login');
        }
        
    } else {
        next();
    }


    console.log('hide: false');
    localStorage.setItem('hide',false);
});

export default router;
