<template>
    <main class="flex-shrink-0">
      <!-- <hr > -->
      <!-- <button class="btn" @click="trythisButton">Expand/Contract</button> -->
      <div class="container my-5 ">
        <div class="p-5 text-center rounded-3">
          <h1 class="text-body-emphasis">Praktische IT Tipps</h1>
          <h6 class="col-lg-8 mx-auto fs-5 text-muted">
            Tipps rund um Technik, Software und Hardware
          </h6><br>
          
        </div>
      </div>

      
      <hr>
      <h4 class="subtitle is-2">Themen</h4>

      <div v-if="isLoading">
            <base-spinner></base-spinner>
        </div>
        <div class="row" v-else-if="hasPits">
            <pits-item
            v-for="sPit in PitsList"
            :key="sPit.id"
            :id="sPit.id"
            :Benutzer_id="sPit.Benutzer_id"
            :title="sPit.title"
            :maintext="sPit.maintext"
            :category="sPit.category"
            :erstellt="sPit.erstellt"
            ></pits-item>
        </div>
        <h3 v-else>Keine Daten gefunden.</h3>
      
    </main>


</template>

<script>
import PitsItem from '../components/pit/PitsItem.vue';

export default {
  components: {
    PitsItem
  },
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
    hasPits() {
      // console.log(this.$store.getters['profile/HasProfiles']);
      // return !this.isLoading && this.$store.getters['profile/HasProfiles'];
      console.log(this.$store.getters.HasPitsData);
      return !this.isLoading && this.$store.getters.HasPitsData;
    },
    PitsList() {
      console.log("Pits: ");
      // console.log(this.$store.getters['profile/Profiles']);
      // return !this.isLoading && this.$store.getters['profile/Profiles'];
      console.log(this.$store.getters.PitsData);
      return !this.isLoading && this.$store.getters.PitsData;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      isFirst: false,
      isFull: false,
    };
  },
  created() {
    this.closeMenu();
    this.loadPits();
  },
  methods: {
    async loadPits(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getPitsdata', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
          limit: 1000,
        });

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    closeMenu() {
      var togglerBody = document.getElementById('navbarCollapse');
      try {
        togglerBody.classList.remove("show");
      } catch (error) {
        togglerBody.classList.remove("hide");
      } 

    },
  }
};


</script>

<style>


#myelement {
  position: relative;
  overflow: hidden;
}

.mycontainer {
  width: 100%;
  height: 100%;
  /* background: url('../../background-digital-services-with-cables-other-line_no_bg.png') 0 0 repeat; */
}

.row {
  margin-top: 5rem;
  margin-bottom: 5rem;
}





.shadows {
    position: relative;
    text-transform: uppercase;
    text-shadow: -15px 5px 20px #ced0d3;
    color: white;
    letter-spacing: -0.05em;
    font-family: 'Anton', Arial, sans-serif;
    user-select: none;
    text-transform: uppercase;
    font-size: 150px;
    transition: all 0.25s ease-out;
}
.shadows:hover {
    text-shadow: -16px 6px 15px #ced0d3;
}

@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes hideSidaBottomText {
  /* 0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 100%); } */
  0% { width:100% }
  100% { width:0% }
}
.animated-title {
  color: #222;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 200;
  height: 90vmin;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vmin;
  
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.animated-title > div div {
  font-size: 7vmin;
  padding: 2vmin 0;
  position: absolute;
}
.animated-title > div div span {
  display: block;
}
.animated-title > div.text-top {
  border-bottom: 1vmin solid #000;
  top: 0;
}
.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
  color: #767676;
}
.animated-title > div.text-bottom {
  bottom: 0;
}
.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}

.animated-title > div.text-bottom div p {
  animation: hideSidaBottomText 0.5s;
  animation-delay: 2s;
  top: 0;
  transform: translate(0, 100%);
  color: #636363;
}


/* .gray-title-hidden {
  transition: opacity 3s ease-in-out,width 3s ease-out;
  width: 0;
  opacity: 0;
}

.gray-title{
  width: 100%;
  opacity: 1;
} */

.sidanet-long-fade-out {
  transition: opacity 2s ease-out,width 3s;
  width: 0;
  opacity: 0;
}

.sidanet-long {
  width: 100%;
  opacity: 1;
}

.sidanet-fade-in {
  transition: opacity 2s ease-in;
  transition-delay: 1s;
  opacity: 1;
}

.sidanet {
  opacity: 0;
}



.btn-new {
	background-color: #000003;
	border-radius: 5px;
	border: 0;
	float: right;
	color: white;
	padding:10px;
}
.btn-new:hover {
	background-color: #000003;
	color: white;
} 
* {
  margin: 0;
  padding: 0;
}
.text-item {
  position: fixed;
  top: 50%;
  left: 48%;
  transform: translateX(-50%) translateY(-50%);
  list-style: none;
  border-bottom: 0;
  letter-spacing: 2px;
}

.text-item.hidden {
  border-bottom: 1px solid #000000;
}

.text-item li {
  display: block;
  float: left;
  font-weight: bold;
  font-size: 2rem;
  color: #000000;
  opacity: 1;
  transition: all 1.5s ease-in;
  max-width: 2em;
}
.text-item.hidden li.spaced {
  padding-left: 0;
}
.text-item li.spaced {
  padding-left: 0.5em;
}

.text-item.hidden li.shade {
  opacity: 0;
  max-width: 0;
}
  
/* 
#wrapper1 {
  background: #ccc;
  overflow: hidden;
  transition: height 200ms;
} */



/* Hide and Seek */

/* #container {
   border: 1px solid black;
   padding: 15px;
}

#top-section {
  border-bottom: 1px solid red;
} */

/* #expand-container1,#expand-container2,#expand-container3 {
  overflow: hidden;
}

#expand-contract {
  border-bottom: 1px solid red;
  margin-top: -100%;
  transition: all 1s;
}

#expand-contract.expanded {
   background-color: green;
   margin-top: 0;
} */



</style>

