<template>
    <main>
      <router-link to="/home" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page"><i class="fa-solid fa-arrow-left fa-2xs"></i> Home </router-link>
      <br>
      <h2>Message Caller Preise</h2>
    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
      
      
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">1 Jahr</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€31,99<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Allinclusive 1 Jahre</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <!-- <button type="button" @click="selectAbo(1)" class="w-100 btn btn-lg btn-primary">Kaufen</button> -->
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">3 Jahre</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€85,99<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Allinclusive 3 Jahre</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <!-- <button type="button" @click="selectAbo(3)" class="w-100 btn btn-lg btn-primary">Kaufen</button> -->
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm border-primary">
          <div class="card-header py-3 text-bg-primary border-primary">
            <h4 class="my-0 fw-normal">5 Jahre</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€139,99<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Allinclusive 5 Jahre</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <!-- <button type="button" @click="selectAbo(4)" class="w-100 btn btn-lg btn-primary">Kaufen</button> -->
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">Kostenlos</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">€0<small class="text-body-secondary fw-light"></small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>14 Tage testen</li>
              <li>Anrufe begrenzt alle 2 Minuten</li>
            </ul>
            <!-- <button type="button" @click="selectAbo(99)" class="w-100 btn btn-lg btn-outline-primary">Jetzt Testen</button> -->
          </div>
        </div>
      </div>
    </div>
    

  </main>
</template>


<script>
export default {
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.closeMenu();
  },
  methods: {
    handleError() {
      this.error = null;
    },
    closeMenu() {
      var togglerBody = document.getElementById('navbarCollapse');
      try {
        togglerBody.classList.remove("show");
      } catch (error) {
        togglerBody.classList.remove("hide");
      } 

    },
  },
};
</script>

<style>
.col {
  margin-bottom: 2rem;
}
</style>