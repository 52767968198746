<template>
    <main>
      <h2>Newsfeed</h2>
      <hr>
      <form>
        <input type="text" name="title" v-model="newsTitle"><button class="btn btn-primary" type="button" @click="addNews">Add Feedentry</button>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" spellcheck="false" name="maintext" data-gramm="false" v-model="newsmaintext"></textarea>
      </form>
      <hr>
      <h4 class="subtitle is-2">News</h4>
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" v-else-if="hasNews">
        <news-short-item
          v-for="sNews in NewsList"
          :key="sNews.id"
          :id="sNews.id"
          :Benutzer_id="sNews.Benutzer_id"
          :title="sNews.title"
          :maintext="sNews.maintext"
          :erstellt="sNews.erstellt"
        ></news-short-item>
      </div>
      <h3 v-else>Keine Daten gefunden.</h3>
    </main>
</template>

<script>
import NewsShortItem from '../../components/news/NewsShortItem.vue';
export default {
  computed: {
    hasNews() {
      // console.log(this.$store.getters['profile/HasProfiles']);
      // return !this.isLoading && this.$store.getters['profile/HasProfiles'];
      console.log(this.$store.getters.HasNewsData);
      return !this.isLoading && this.$store.getters.HasNewsData;
    },
    NewsList() {
      console.log("News: ");
      // console.log(this.$store.getters['profile/Profiles']);
      // return !this.isLoading && this.$store.getters['profile/Profiles'];
      console.log(this.$store.getters.NewsData);
      return !this.isLoading && this.$store.getters.NewsData;
    },
  },
  components: {
    NewsShortItem,
  },
  data() {
    return {
      newsTitle: '',
      newsmaintext: '',
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.loadNews();
    this.closeMenu();
  },
  methods: {
    closeMenu() {
      var togglerBody = document.getElementById('navbarCollapse');
      try {
        togglerBody.classList.remove("show");
      } catch (error) {
        togglerBody.classList.remove("hide");
      } 

    },
    async loadNews(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('getnewsdata', {
          forceRefresh: refresh,
          token: this.$store.state.auth.sessiontoken,
          userid: this.$store.state.auth.userId,
          limit: 1000,
        });

      } catch (error) {
        this.error = error.message || 'Something went wrong!';
      }
      this.isLoading = false;
    },
    async addNews() {
      //call method to insert News to DB
      this.isLoading = true;
      console.log("isloading true");
      try {
              await this.$store.dispatch('addNews',{
                  token: this.$store.state.auth.sessiontoken,
                  userid: this.$store.getters.userId,
                  title: this.newsTitle,
                  maintext: this.newsmaintext,
              });

              this.loadNews();
              this.isLoading = false;
              this.newsTitle = "";
              this.newsmaintext = "";
              console.log("isloading false");

      } catch (error) {
          
          this.isLoading = false;
          console.log("isloading false");
          this.error = error.message;
      }
    },
  },
};
</script>