<template>

  <div class="col">

    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">{{ title }}</h4>
      </div>
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <div class="btn-group">
            <button v-if="isAdmin" type="button" @click="editPit" class="btn btn-tool text-success" data-card-widget="remove">
              <i class="fas fa-cog"></i>
            </button>
            <button v-if="isAdmin" type="button" @click="delPit" class="btn btn-tool text-danger" data-card-widget="remove">
              <i class="fas fa-trash"></i>
            </button>
          </div>
          <small class="text-body-secondary">{{ erstellt }}</small>
          
        </div>
      </div>
    </div>


  </div>

  </template>
  
  <script>
  export default {
    props: ['id','Benutzer_id', 'title', 'maintext','category', 'erstellt'],
    data() {
      return {
      };
    },
    computed: {
        isAdmin() {
          return this.$store.getters.isAdmin;
        },
    },
    methods: {
      editProfile() {
          console.log('isAuth: ' + this.$store.getters.isAuthenticated);
        },
      closeEditProfile() {
          this.doProfileEdit = false;
          console.log('isAuth: ' + this.$store.getters.isAuthenticated);
        },
      delItem() {

      }
    }
  }
  </script>
