import  axios   from 'axios';

export default {
    async getPitsdata(context,payload) {
        // console.log(context,payload);
        try {
            const httplink = context.state.serverlink+'getPitList.php?key=9999';
            console.log(httplink);
            const response = await axios.post(httplink, {
            userid: payload.userid,
            token: payload.token,
            limit: payload.limit,
            });

            console.log(response.data);

            const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
            console.log(parsedResponse);
            
            const pitsdata = [];

            for (const key in parsedResponse) {
                if (parsedResponse[key].id != null){
                    const pitsline = {
                        id: parsedResponse[key].id,
                        title: parsedResponse[key].title,
                        Benutzer_id: parsedResponse[key].Benutzer_id,
                        maintext: parsedResponse[key].maintext,
                        category: parsedResponse[key].category,
                        erstellt: parsedResponse[key].erstellt,
                    };
                    pitsdata.push(pitsline);
                }
                
              }

            context.commit('setPitsData', pitsdata);
            console.log(context);

        } catch (error) {
            console.error(error);
        }
    },
    async addPit(context,payload) {
        console.log('we adding pits!');
        console.log(payload);
        const httplink = context.state.serverlink+'addPit.php?key=9999';
        console.log(httplink);
        const response = await axios.post(httplink, {
        userId: payload.userid,
        sessiontoken: payload.token,
        id: payload.id,
        title: payload.title,
        maintext: payload.maintext,
        category: payload.category,
        });


        const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
        console.log(parsedResponse);
        const line = {
                    status: parsedResponse["status"],
                    message: parsedResponse["message"],
                    
                };
        if(line.status == 'error') {
            console.log('error throwed: '+ line.message);
            throw new Error(line.message);
        }
        // console.log(context);


    },
    async delPit(context,payload) {
        console.log('we deleting pits!');
        console.log(payload);
        const httplink = context.state.serverlink+'delPit.php?key=9999';
        console.log(httplink);
        const response = await axios.post(httplink, {
        userId: payload.userid,
        sessiontoken: payload.token,
        id: payload.id,
        });


        const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
        console.log(parsedResponse);
        const line = {
                    status: parsedResponse["status"],
                    message: parsedResponse["message"],
                    
                };
        if(line.status == 'error') {
            console.log('error throwed: '+ line.message);
            throw new Error(line.message);
        }
        // console.log(context);


    },
    async uploadPic(context,payload) {
        console.log('we deleting pits!');
        console.log(payload);
        const httplink = context.state.serverlink+'delPit.php?key=9999';
        console.log(httplink);
        const response = await axios.post(httplink, {
        userId: payload.userid,
        sessiontoken: payload.token,
        id: payload.id,
        });


        const parsedResponse = JSON.parse(response.data.replace("<!DOCTYPE html>",""));
        console.log(parsedResponse);
        const line = {
                    status: parsedResponse["status"],
                    message: parsedResponse["message"],
                    
                };
        if(line.status == 'error') {
            console.log('error throwed: '+ line.message);
            throw new Error(line.message);
        }
        // console.log(context);


    }

};