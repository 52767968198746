<template>

  <div class="col">

    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">{{ title }}</h4>
      </div>
      <div class="card-body">
        <!-- <h1 class="card-title pricing-card-title">{{ title }}</h1> -->
        <p class="mt-3 mb-4">{{ maintext }}</p>
        <div class="d-flex justify-content-between align-items-center">
          <div class="btn-group">
            <!-- <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
            <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button> -->
          </div>
          <small class="text-body-secondary">{{ erstellt }}</small>
            
          <button v-if="isAdmin" type="button" class="btn btn-tool text-danger" data-card-widget="remove">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>


  </div>

  </template>
  
  <script>
  export default {
    props: ['id','Benutzer_id', 'title', 'maintext', 'erstellt'],
    data() {
      return {
      };
    },
    computed: {
        isAdmin() {
          return this.$store.getters.isAdmin;
        },
    },
    methods: {
      editProfile() {
          console.log('isAuth: ' + this.$store.getters.isAuthenticated);
        },
      closeEditProfile() {
          this.doProfileEdit = false;
          console.log('isAuth: ' + this.$store.getters.isAuthenticated);
        },
      delItem() {

      }
    }
  }
  </script>
