<template>


    <div class="card mb-4 rounded-3 shadow-sm">
      <div class="card-header py-3">
        <h4 class="my-0 fw-normal">{{ title }}  - ({{ category }})</h4>
        <small class="text-body-secondary">{{ erstellt }}</small>
        <button v-if="isAdmin" type="button" @click="editPit" class="btn btn-tool text-success">
            <i class="fas fa-cog"></i>
          </button>
          <button v-if="isAdmin" type="button" @click="delPit" class="btn btn-tool text-danger">
            <i class="fas fa-trash"></i>
          </button>
      </div>
    </div>


  </template>
  
  <script>
  export default {
    props: ['id','Benutzer_id', 'title', 'maintext','category', 'erstellt'],
    emits: ['Item-Edit','Item-Del'],
    data() {
      return {
      };
    },
    computed: {
        isAdmin() {
          return this.$store.getters.isAdmin;
        },
    },
    methods: {
      editPit() {
        console.log("emit Edit"+this.title);
        this.$emit('Item-Edit',this.id,this.Benutzer_id,this.title,this.maintext,this.category);
      },
      delPit() {
        console.log("emit Del"+this.title);
        this.$emit('Item-Del',this.id);
      },
    },
  }
  </script>
