<template>
  <main>
    <router-link to="/home" class="p-2 btn btn-sm text-secondary align-bottom" aria-current="page"><i class="fa-solid fa-arrow-left fa-2xs"></i> Home </router-link>
    <br>
    <div class="container my-5">
      <div class="p-5 text-center bg-body-tertiary rounded-3">
        <i class="fas fa-store fa-3x"></i>
        <h1 class="text-body-emphasis">Waitery</h1>
        <p class="col-lg-8 mx-auto fs-5 text-muted">
          Effektive Alternative zum Ordermansystem. (ohne Registrierkasse)
          Sehr Benutzerfreundlich und übersichtlich.
          Betreuung vorort inkl.
        </p>
        <div class="d-inline-flex gap-2 mb-5">         
          <a href="https://waitery.sidanet.at" class="p-2 btn btn-primary btn-lg rounded-pill" aria-current="page">Zur Online Vorschau</a><br>
          <a href="tel: 00436036399" class="btn btn-outline-secondary btn-lg rounded-pill">Anrufen</a>
        </div>
      </div>
    </div>
    <br>
    <h3>Preise</h3>
    <hr>
    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm border-primary">
          <div class="card-header py-3 text-bg-primary border-primary">
            <h4 class="my-0 fw-normal">Waitery Erstertag</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">300,-</h1><small class="text-body-secondary fw-light">(ohne Mwst.)</small>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Benutzung der Harware und Software</li>
              <li>Remotesupport</li>
              <li>Einrichtung und Aufbau inkl.</li>
              <li>Schulung und Erklärung inkl. (ca. 1h)</li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card mb-6 rounded-3 shadow-sm">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">Waitery weiterer Tag</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">200,-</h1><small class="text-body-secondary fw-light">(ohne Mwst.)</small>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Benutzung der Harware und Software</li>
              <li>Remotesupport</li>
            </ul>
          </div>
        </div>
      </div>
      
    </div>
  </main>
</template>


<script>
export default {
  computed: {
    isLoggedin() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.closeMenu();
  },
  methods: {
    handleError() {
      this.error = null;
    },
    closeMenu() {
      var togglerBody = document.getElementById('navbarCollapse');
      try {
        togglerBody.classList.remove("show");
      } catch (error) {
        togglerBody.classList.remove("hide");
      } 

    },
  },
};
</script>

<style>
.col {
  margin-bottom: 2rem;
}
</style>