import {createStore} from 'vuex';

import authModule from './modules/auth/index.js';
import profileModule from './modules/profile/index.js';
import aboModule from './modules/abo/index.js';
import customerModule from './modules/customer/index.js';
import phonehistoryModule from './modules/phonehistory/index.js';
import shopModule from './modules/shop/index.js';
import newsModule from './modules/news/index.js';
import pitsModule from './modules/pits/index.js';


// console.log('before.store');
const store = createStore({
    modules: {
        auth: authModule,
        profile: profileModule,
        abo: aboModule,
        customer: customerModule,
        phonehistory: phonehistoryModule,
        shop: shopModule,
        news: newsModule,
        pits: pitsModule
    }
});

// console.log('store.index');

export default store;